// import { environment } from "$lib/consts";
// import { CaptureConsole } from "@sentry/integrations";
// import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
// import * as Sentry from '@sentry/sveltekit';

// TODO: Disabled until we either get off cloudflare workers, or sentry fixes their cloudflare adapter support
// see https://github.com/getsentry/sentry-javascript/issues/8291 or https://github.com/getsentry/sentry-javascript/issues/9176

// Sentry.init({
//   dsn: 'https://4b0e5c4bac7ca4f2850e957453423597@o4505847028842496.ingest.sentry.io/4505847030218752',
//   tracesSampleRate: 1.0,

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,

//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,

//   // If you don't want to use Session Replay, just remove the line below:
//   integrations: [
//     new Replay({
//       networkDetailAllowUrls: [/.*/],
//       maskAllText: false,
//       maskAllInputs: false,
//       blockAllMedia: false
//     }),
//     // new CaptureConsole()
//   ],
//   environment: environment,
// });

// Sentry.setTag('sveltekit', 'browser');

// // If you have a custom error handler, pass it to `handleErrorWithSentry`
// export const handleError = handleErrorWithSentry();

import * as SentrySvelte from '@sentry/svelte';
import { SENTRY_DSN, environment } from '$lib/consts';
import type { HandleClientError } from '@sveltejs/kit';
import { FeatureFlagService } from '$lib/services/featureFlag.service';
import { get } from 'svelte/store';

if (get(FeatureFlagService.isSentryEnabled)) {
	// TODO: Only enable sentry in production and opt-in for dev.
	// should depend on the existence of DSN being defined as an environment variable.
	SentrySvelte.init({
		dsn: SENTRY_DSN,
		tracesSampleRate: get(FeatureFlagService.sentryTraceSampleRate),

		// This sets the sample rate to be 100%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: get(
			FeatureFlagService.sentryReplaysSessionSampleRate
		),

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: get(
			FeatureFlagService.sentryReplaysOnErrorSampleRate
		),

		integrations: [
			SentrySvelte.replayIntegration({
				networkDetailAllowUrls: [/.*/],
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false
			}),
			SentrySvelte.replayCanvasIntegration(),
			SentrySvelte.browserTracingIntegration()
		],
		environment: environment
	});

	SentrySvelte.setTag('svelteKit', 'browser');
}

export const handleError = (({ error, event }) => {
	if (get(FeatureFlagService.isSentryEnabled)) {
		SentrySvelte.captureException(error as Error, {
			contexts: { sveltekit: { event } }
		});
		return {
			message: (error as Error).message
		};
	} else {
		return {
			message: (error as Error).message
		};
	}
}) as HandleClientError;
