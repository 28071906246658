import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102')
];

export const server_loads = [];

export const dictionary = {
		"/": [19],
		"/(user)/(organizer)/(admin)/admin": [21,[2,3,4,5]],
		"/(user)/chats": [36,[2]],
		"/(user)/chats/scheduled": [38,[2]],
		"/(user)/chats/schedule": [37,[2]],
		"/(user)/(super-admin)/companies": [26,[2,7]],
		"/(user)/(super-admin)/companies/add": [35,[2,7]],
		"/(user)/(super-admin)/companies/[name]": [27,[2,7,8]],
		"/(user)/(super-admin)/companies/[name]/events": [28,[2,7,8,9]],
		"/(user)/(super-admin)/companies/[name]/events/past": [29,[2,7,8,9]],
		"/(user)/(super-admin)/companies/[name]/events/upcoming": [30,[2,7,8,9]],
		"/(user)/(super-admin)/companies/[name]/overview": [31,[2,7,8]],
		"/(user)/(super-admin)/companies/[name]/settings": [32,[2,7,8]],
		"/(user)/(super-admin)/companies/[name]/users": [33,[2,7,8,10]],
		"/(user)/(super-admin)/companies/[name]/users/[role]": [34,[2,7,8,10]],
		"/(user)/connections": [39,[2,11]],
		"/(user)/connections/all-connections": [40,[2,11]],
		"/(user)/connections/events": [41,[2,11]],
		"/(user)/connections/favorites": [42,[2,11]],
		"/(user)/create-account": [43,[2,12]],
		"/(user)/create-account/step-2": [44,[2,12]],
		"/(user)/create-account/step-3": [45,[2,12]],
		"/(user)/create-account/step-4": [46,[2,12]],
		"/(user)/create-account/step-5": [47,[2,12]],
		"/(user)/(organizer)/dashboard": [22,[2,3,6]],
		"/(user)/(organizer)/dashboard/events": [23,[2,3,6]],
		"/(user)/(organizer)/dashboard/new-connections": [24,[2,3,6]],
		"/(user)/(organizer)/dashboard/new-members": [25,[2,3,6]],
		"/(user)/discover": [48,[2,13]],
		"/(user)/discover/all": [49,[2,13]],
		"/(user)/discover/near-you": [50,[2,13]],
		"/(user)/discover/new": [51,[2,13]],
		"/(user)/discover/recommended": [52,[2,13]],
		"/(user)/discover/searchResults": [53,[2,13]],
		"/(anonymous)/eventprompts/[id]": [20],
		"/(user)/events": [54,[2]],
		"/(user)/events/all": [56,[2,14]],
		"/(user)/events/all/past": [57,[2,14]],
		"/(user)/events/all/upcoming": [58,[2,14]],
		"/(user)/events/create": [59,[2,15]],
		"/(user)/events/create/chapters": [60,[2,15]],
		"/(user)/events/create/details": [61,[2,15]],
		"/(user)/events/create/event-details": [62,[2,15]],
		"/(user)/events/create/final-look": [63,[2,15]],
		"/(user)/events/create/groups": [64,[2,15]],
		"/(user)/events/create/invite-members": [65,[2,15]],
		"/(user)/events/create/prompts": [66,[2,15]],
		"/(user)/events/create/upload-video": [67,[2,15]],
		"/(user)/events/[id]": [55,[2]],
		"/(user)/livestream": [68,[2]],
		"/(user)/livestream/join": [69,[2]],
		"/(user)/livestream/videocall": [70,[2]],
		"/(user)/livestream/welcome": [71,[2]],
		"/(user)/logged-out": [72,[2]],
		"/(user)/organization": [~73,[2,16]],
		"/(user)/organization/interests": [75,[2,16]],
		"/(user)/organization/skills": [76,[2,16]],
		"/(user)/organization/values": [77,[2,16]],
		"/(user)/organization/[view]": [74,[2,16]],
		"/(user)/profile": [78,[2]],
		"/(user)/profile/edit": [79,[2]],
		"/(user)/profile/home": [80,[2]],
		"/(user)/profile/interests": [81,[2]],
		"/(user)/profile/languages": [82,[2]],
		"/(user)/profile/location": [83,[2]],
		"/(user)/profile/skills": [84,[2]],
		"/(user)/scheduled": [85,[2]],
		"/(user)/scheduled/join": [86,[2]],
		"/(user)/scheduled/new-videocall": [87,[2]],
		"/(user)/settings": [88,[2,17]],
		"/(user)/spotlight": [89,[2]],
		"/(user)/talent": [90,[2,18]],
		"/(user)/talent/[view]": [91,[2,18]],
		"/(user)/teams": [92,[2]],
		"/(user)/videocycle": [93,[2]],
		"/(user)/videocycle/break": [94,[2]],
		"/(user)/videocycle/chapter": [95,[2]],
		"/(user)/videocycle/done": [96,[2]],
		"/(user)/videocycle/join": [97,[2]],
		"/(user)/videocycle/new-videocall": [98,[2]],
		"/(user)/videocycle/quiz": [99,[2]],
		"/(user)/videocycle/review": [100,[2]],
		"/(user)/videocycle/waiting": [101,[2]],
		"/(user)/videocycle/welcome": [102,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';